import React, { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { FieldToUpdate } from '@tls/slt-types';

import { getMapFunction, OnGroupValueChangeFunc } from '../utils/getMapFunction';
import { getInputValueKey } from '../utils/getInputValueKey';
import {
  GroupFieldToUpdate,
  useMutationFormatGroupDataValue,
} from '../../../shared/mutations/viewTaxReturn';
import { CHECKBOX_CHECKED, CHECKBOX_UNCHECKED, FORMATTING_ERROR } from '../../constants';
import { errorNotification } from '../../../shared/notification/store/actions';
import { OverridableField } from '../types';

type FieldsToUpdate = Array<{ blockId: string; mappingID: string }>;

interface UpdateFieldsInput {
  fieldsToUpdate: FieldsToUpdate;
  keyToSet: keyof OverridableField;
  newValue: typeof CHECKBOX_CHECKED | typeof CHECKBOX_UNCHECKED;
  additionalPropsToChange: Record<string, unknown>;
  formattedFields?: { [mappingID: string]: string };
  formattingError?: boolean;
}

interface OverridableFormInputsGroupWrapperProps {
  groupItems: Array<OverridableField>;
  isUpdatingFormValues: boolean;
  setFocusedElementName: React.Dispatch<React.SetStateAction<null | string>>;
}

const OverridableFormInputsGroupWrapper = ({
  groupItems,
  isUpdatingFormValues,
  setFocusedElementName,
}: OverridableFormInputsGroupWrapperProps) => {
  const dispatch = useDispatch();
  const { values: formValues, setValues: setFormValues } = useFormikContext<{
    [blockId: string]: OverridableField;
  }>();
  const formatGroupDataValue = useMutationFormatGroupDataValue();

  const isGroupSuppressed = useMemo(
    () =>
      groupItems.every(({ printSuppress }) => printSuppress) &&
      !groupItems.some(({ isOverridden }) => isOverridden),
    [groupItems],
  );

  const groupFieldsInForms = useMemo(
    () => groupItems.map(({ blockId }) => formValues?.[blockId]).filter(field => field),
    [formValues, groupItems],
  );

  const displayFormattingError = useCallback(
    (errorGroupBlockIds: Array<string>) => {
      dispatch(errorNotification(`Formatting ${errorGroupBlockIds.join(', ')} failed.`));
    },
    [dispatch],
  );

  const updateFields = useCallback(
    ({
      fieldsToUpdate,
      formattedFields,
      keyToSet,
      newValue,
      formattingError = false,
      additionalPropsToChange = {},
    }: UpdateFieldsInput) => {
      const newValues: {
        [blockId: string]: OverridableField;
      } = fieldsToUpdate.reduce((acc, { blockId, mappingID }) => {
        const formattedData: string = formattingError
          ? FORMATTING_ERROR
          : formattedFields?.[mappingID] || '';
        return {
          ...acc,
          [blockId]: {
            ...formValues[blockId],
            formattedData,
            [keyToSet]: newValue,
            ...additionalPropsToChange,
          },
        };
      }, {});

      setFormValues(previousValues => ({
        ...previousValues,
        ...newValues,
      }));
    },
    [setFormValues, formValues],
  );

  const onGroupValueChange: OnGroupValueChangeFunc = useCallback(
    async ({ newValue: triggerNewValue, additionalPropsToChange, valueKey }, triggerBlockId) => {
      const groupChangeInfoMap: {
        [blockId: string]: {
          fieldsToUpdate: FieldsToUpdate;
          keyToSet: keyof OverridableField;
          newValue: typeof CHECKBOX_CHECKED | typeof CHECKBOX_UNCHECKED;
        };
      } = {};
      const groupChangePayloads: GroupFieldToUpdate = {};
      groupFieldsInForms.forEach(field => {
        const { isOverridden, blockId, isEditable, mappingID, siblings = [] } = field;

        const newValue: typeof CHECKBOX_CHECKED | typeof CHECKBOX_UNCHECKED =
          triggerBlockId === blockId ? triggerNewValue : CHECKBOX_UNCHECKED;
        const keyToSet: keyof OverridableField =
          valueKey ||
          getInputValueKey({
            isOverridden: Boolean(isOverridden),
            isEditable: Boolean(isEditable),
          });
        const fieldsToUpdate: FieldsToUpdate = [
          { blockId, mappingID: mappingID || '' },
          ...siblings,
        ];
        const payload: Array<FieldToUpdate> = fieldsToUpdate.map(({ mappingID }) => ({
          mappingID: mappingID || '',
          dataInstanceValue: newValue,
        }));
        if (field[keyToSet] === newValue && !additionalPropsToChange?.isClearingOverride) {
          return;
        }

        groupChangeInfoMap[blockId] = { fieldsToUpdate, keyToSet, newValue };
        groupChangePayloads[blockId] = payload;
      });

      try {
        const { formattedFields: formattedFieldsList } = await formatGroupDataValue.mutateAsync(
          groupChangePayloads,
        );
        Object.entries(formattedFieldsList).forEach(([blockId, formattedFields]) => {
          const { fieldsToUpdate, keyToSet, newValue } = groupChangeInfoMap[blockId];
          updateFields({
            fieldsToUpdate,
            formattedFields,
            keyToSet,
            newValue,
            additionalPropsToChange,
          });
        });
      } catch (err) {
        displayFormattingError(Object.keys(groupChangeInfoMap));
        Object.values(groupChangeInfoMap).forEach(({ fieldsToUpdate, keyToSet, newValue }) => {
          updateFields({
            fieldsToUpdate,
            keyToSet,
            newValue,
            formattingError: true,
            additionalPropsToChange,
          });
        });
      }
    },
    [updateFields, displayFormattingError, formatGroupDataValue, groupFieldsInForms],
  );

  const mappedGroupItems = useMemo(
    () =>
      groupItems.map(
        getMapFunction({
          isGroupSuppressed,
          isUpdatingFormValues,
          setFocusedElementName,
          onGroupValueChange,
        }),
      ),
    [
      onGroupValueChange,
      isGroupSuppressed,
      isUpdatingFormValues,
      setFocusedElementName,
      groupItems,
    ],
  );

  return <div role="radiogroup">{mappedGroupItems}</div>;
};

export default OverridableFormInputsGroupWrapper;
